
.dateAgeOnLeft {
  float: left;
  text-align: right;
  width: 20px;
  margin-right: 15px
}

.dateDueIn {
  color: orange;
}

.datePast { 
  color: red;
}

.dateConfirmed {
  background-color: lightgreen;
  
}

/* SAP */ 

.bomTypeAssembly {
  color:darkblue;
}

.bomTypeSales {
  color: darkred;
}

.bomTypeProduction {
  color: green;
}

.bomTypeTemplate {
  color: coral;
}

.sapDocStatusOpen {
  color: darkgreen;
}

.sapDocStatusClosed {
  color: darkgray;
}

.sapDocStatusCanceled {
  color: darkgray;
  text-decoration: line-through;
}

.sapDocStatusPlanned {
  color: orange;
}

.sapDocStatusReleased {
  color: green;
}

.sapDocStatusAppWaiting {
  color: orange;
}

.rowUpdatedRecently {
  background-color: lightgoldenrodyellow;
}

.changedSAPItemName {
  background-color: lightgoldenrodyellow; 
}

.lineTypeParent {
  font-weight: bolder;
  color: #000000
}

.lineTypeText {
  font-weight: bold;
  text-decoration: underline;
  color: #00B0F0
}

.lineTypeSubTotal {
  color: blue;
  font-weight: bold;
  text-decoration: underline;
}

.lineTypeInactiveItem {
  color: red;
  text-decoration: line-through;
}

.lineTypeDeprecatedItem {
  background-color: lightcoral !important;  
  text-decoration: line-through;
}



.sapApprovalStatusApproved {
  color: green;
}

.sapApprovalStatusDenied {
  color:red
}

/* JIRA */


/*

    STATUS BUBLES

*/

.statusBuble {
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #42526e;
    display: inline-block;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 2px;
    padding-right: 2px; 
    border-radius: 3px;
    box-sizing: border-box;    
}

.statusBuble_Green {
    background-color: #e3fcef;
    border-color: #e3fcef;
    color: #064;
}

.statusBuble_Purple {
    background-color: purple;
    border-color: purple;
    color: orange;
}

.statusBuble_Aqua {
    background-color: aquamarine;
    border-color: aquamarine;
    color: black;
}

.statusBuble_Blue {
    background-color: #deebff;
    border-color: #deebff;
    color: #0049b0;
}

.statusBuble_Red {
    background-color: lightcoral;
    border-color: lightcoral;
    color: rgb(128, 0, 0);
}

.statusBuble_Orange {
    background-color: orange;
    border-color: orange;
    color: black;
}

.statusBuble_Grey {
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #42526e;
}

.HighlighterMark {
    background-color: 'orange';
    color: inherit;
    font-weight: bold;
    padding: 0;
}

.negativeQtyHightlight {
    background-color: 'red' !important;
    color: 'red' !important;
}

.zeroQtyHightlight {
    background-color: 'red'
}

.jiraAvatar {
    border-radius: 50%;
    margin-right: 0.5rem;
    max-height: 24px;
}
