.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
	  white-space: normal !important;
	  text-overflow: clip !important;
    padding-right: 1px !important;
    font-size: 11px !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
    align-items: start !important;
    top: 5px !important;
    right: 0px !important;
}

.tabulator-row .tabulator-cell{
    padding: 2px 3px !important;
    /* height: 23px !important; */
    font-size: 13px !important;
}

.tabulator .tabulator-disabled {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.hover_show { 
    display: block-inline;
    color: lightgrey;
}


.tabulator-row:hover .hover_show { 
    display: block-inline !important;
    color: blue;
}
