:root {
	--bp-purple-dark: #2D1955;
	--bp-purple-light: #A096FA;
	--bp-blue-dark: #0A1E32;
	--bp-blue-light: #1e90ff;
	--bp-red: #F05028;
	--bp-white: #F5F0F0;

  --ph-blue: #598DCD;
  --ph-white: #FFFFFF;

  --bs-primary: #2D1955;
}

@font-face {
	font-family: Brightpick;
	src: url('../Assets/Brightpick-Regular2.ttf');
	font-weight: normal;
}

#root > nav > div.navbar-nav > div > a,
#root > nav > div.flex-row.justify-content-end.float-right.me-0.navbar-nav > div.navbar-collapse.collapse > div > a,
#root > nav > div.flex-row.justify-content-end.float-right.me-0.navbar-nav > div.navbar-collapse.collapse.show > div > a
{
    padding-top: 0px;
    padding-bottom: 0px;
}

/* CORPORATE IDENITY */ 

.BPLogoFont {
	background-color: var(--bp-purple); 
	color: var(--bp-red) !important;
	font-family: Brightpick;
}

.BPLogoColorWhite {
	color: var(--bp-white) !important;
}

.BPLogoColorRed {
	color: var(--bp-red) !important;
}

.BPnavbar {
	background-color: var(--bp-purple-dark); 
	font-family: Poppins;
}

.PHnavbar {
	background-color: var(--ph-blue); 
	font-family: Poppins;
}

.navbar-nav .dropdown-menu {
		background-color: var(--bp-white) !important;
}

.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item:focus {
		color: var(--bp-white) !important;
		background-color: var(--bp-purple-light)
}

.modal-75w {
    top: 50px;
    max-width: 75vw !important;
    max-height: 75vh !important;
    overflow-y: hidden  ;
}

.modal-90w {
    top: 50px;
    max-width: 90vw !important;
    max-height: 90vh !important;
}

.chart-container {
    position: relative;
    margin: auto;
    height: 30vh;
}

.modal-body > .chart-container {
    position: relative;
    margin: auto;
    height: 75vh;
}

@media (max-width: 480px) {
  .chart-container {
      position: relative;
      margin: auto;
      height: 25vh;
  }
}

@media (min-width: 2500px) {
  .chart-container {
      position: relative;
      margin: auto;
      height: 23vh;
  }
}
/*
    padding: 1rem 1rem 1rem 1rem;
    height: calc(100vh - 57px); 

*/

.AppContent {
    padding: 1rem 1rem 1rem 1rem;
    height: calc(100vh - 57px);
    /* margin-top: 57px;*/  
    overflow-y: auto;
    background-color: #F5F5F5;
    font-size: 0.875rem
}

@media (min-width: 2500px) {
  .AppContent {
    font-size: 0.95rem;
  }
}

@media (min-width: 3500px) {
  .AppContent {
    font-size: 1.5rem;
  }
}

.offcanvas {
     top: 57px !important; 
}  


.Toastify__toast-container--top-right {
  top: 5em
}

.highlighterSearch {
    background-color: orange;
    padding: 0px;
   
}


.dropdown-menu
{
  width: 600px;
}



